
import { B_REST_Utils } from "@/bREST/core/classes";
import { B_REST_VueApp_base, B_REST_Vuetify_PickerDef } from "@/bREST/core/implementations/vue";

//NOTE: If we need to add new langs later, will need to add for frontend & backend's core & custom locs + imports for Vuetify locale (Check B_REST_App_base.js)
import locMsgs_custom_fr from "./loc/fr.json";
import locMsgs_custom_en from "./loc/en.json";

import App from "./App.vue";


import PublicLayoutComponent from "./layouts/PublicLayout.vue";
import MainLayoutComponent   from "./layouts/MainLayout.vue";

import "@/custom/components/base/_autoload.js";






export default class MyApp extends B_REST_VueApp_base
{
	static get CURRENT_SESSION_INFO_DT_NOW_UPDATE_INTERVAL_SECS() { return 60; } //Can be 15,30,60,120, but not 45 or 90. NOTE: Used to cause calendar to stutter because of nested computed/watches, and seems like it still does if we open an event form in staff's mySchedule
	                                                                             //WARNING: If change to x_MINUTES interval, will have to change dt_deltaSeconds() to dt_deltaMinutes()
	
	_shouldShowBackNavBtn = false; //For when we go from a list to a form, or ex client form to sub thing. WARNING: For now, not accurate, because if we start in a sub page, we have no nav triggering calculating this
	
	static _consts = {
		wpVirtualViewPerms: {
			//Must match w server's bREST_Custom::WP_VIRTUAL_VIEW_PERMS_x
			ALL:       "all",
			ONE:       "one",
			FREE_ONLY: "freeOnly",
			NONE:      "none",
		},
		brAuthPrompts: {
			attrsVCard:   {color:"transparent", maxWidth:500, flat:true},
			attrsMainBtn: {color:"success", filled:true, rounded:true, xLarge:true, class:"text-none"},
			attrsAltBtn:  {text:true, small:true, class:"text-caption text-decoration-underline"},
		},
		//Relative to /_businessFiles/policies/ dir. IMPORTANT: Server's bREST_Custom::$_POLICIES_LINKS & frontend's MyApp.consts.policiesLinks must match
		policiesLinks: {
			privacy:            {fr:"privacy/Politique-de-confidentialite.pdf",               en:"privacy/Privacy-policy.pdf"},
			termsAndConditions: {fr:"termsAndConditions/Clauses-conditions-consommateur.pdf", en:"termsAndConditions/Consumer-terms-and-conditions.pdf"},
			spap:               {fr:"spap/Sante-par-la-pratique-de-lactivite-physique.pdf",   en:"spap/Physical-activity-training-for-health.pdf"},
		},
		user_types: {
			ADMIN:  "admin",
			STAFF:  "staff",
			CLIENT: "client",
		},
		weekdays: {
			SUNDAY:    0,
			MONDAY:    1,
			TUESDAY:   2,
			WEDNESDAY: 3,
			THURSDAY:  4,
			FRIDAY:    5,
			SATURDAY:  6,
		},
		seasons: {
			WINTER: "winter",
			SPRING: "spring",
			SUMMER: "summer",
			AUTUMN: "autumn",
		},
		durations: {
			30: "30",
			45: "45",
			60: "60",
		},
		configSessionMembership_type: {
			PRESENTIAL:         "presential",
			PRESENTIAL_SPECIAL: "presentialSpecial",
			VIRTUAL:            "virtual",
			VIRTUAL_AS_COMBO:   "virtualAsCombo",
		},
		staff_calc_roles_summary_all: "*",
		session_priceGridRange: {
			PRIORITY_REG: "priorityReg",
			GEN_REG:      "genReg",
			EVENTS:       "events",
		},
		session: {
			//IMPORTANT: If change, must also change in Model_Session::VIRTUAL_x_PRICE
			VIRTUAL_WEEKLY_PRICE:          9.99,
			VIRTUAL_DAILY_PRICE:           1.43,
			VIRTUAL_AS_COMBO_WEEKLY_PRICE: 4.99,
			VIRTUAL_AS_COMBO_DAILY_PRICE:  0.71,
		},
		client_gender: {
			F: "f",
			M: "m",
		},
		eventClient_occurrenceType: {
			NORMAL:        "normal",
			TRIAL:         "trial",
			MAKEUP:        "makeUp",
			PRIVATE_GROUP: "privateGroup",
			SPECIAL_EVENT: "specialEvent",
			FLEX:          "flex",
			A_LA_CARTE:    "aLaCarte",
			STAFF:         "staff",
		},
		eventClient_occurrenceState: {
			NOT_REGGED:        "-",
			UPCOMING:          "?",
			CAME:              "o",
			VALID_CANCELATION: "x",
			NO_SHOW:           "!",
		},
		event_type: {
			PRESENTIAL:     "presential",
			VIRTUAL:        "virtual",
			PRIVATE_GROUP:  "privateGroup",
			SPECIAL_EVENT:  "specialEvent",
		},
		staffRole_type: {
			ADMIN:              "admin",
			FRANCHISEE_OWNER:   "franchiseeOwner",
			FRANCHISEE_MANAGER: "franchiseeManager",
			COACH:              "coach",
			COACH_SUPERVISOR:   "coachSupervisor",
			TRAINEE:            "trainee",
		},
		clientFranchiseeToken_type: {
			TRIAL:  "trial",
			MAKEUP: "makeUp",
		},
		client_calc_type: {
			PROSPECT:         "prospect",
			PROSPECT_WTRIALS: "prospectWTrials",
			W_CONTRACTS:      "wContracts",
		},
		configProgram_stats_musculation: {
			LOW:  "low",
			MID:  "mid",
			HIGH: "high",
		},
		configProgram_stats_flexibility: {
			LOW:  "low",
			MID:  "mid",
			HIGH: "high",
		},
		configProgram_stats_intensity: {
			LOW:  "low",
			MID:  "mid",
			HIGH: "high",
		},
		configProgram_stats_cardio: {
			LOW:  "low",
			MID:  "mid",
			HIGH: "high",
		},
		configProgram_stats_movement: {
			WALK:      "walk",
			FAST_WALK: "fastWalk",
			RUNNING:   "running",
			NONE:      "none",
		},
		regFlow_reason: {
			NORMAL_PRESENTIAL:    "normal_presential",
			NORMAL_VIRTUAL:       "normal_virtual",
			NORMAL_COMBO:         "normal_combo",
			NORMAL_REVOKE:        "normal_revoke",
			MAKEUP:               "makeUp",
			MAKEUP_REVOKE:        "makeUp_revoke",
			TRIAL:                "trial",
			TRIAL_REVOKE:         "trial_revoke",
			PRIVATE_GROUP:        "privateGroup",
			PRIVATE_GROUP_REVOKE: "privateGroup_revoke",
			SPECIAL_EVENT:        "specialEvent",
			SPECIAL_EVENT_REVOKE: "specialEvent_revoke",
			FLEX:                 "flex",
			FLEX_REVOKE:          "flex_revoke",
			A_LA_CARTE:           "aLaCarte",
			A_LA_CARTE_REVOKE:    "aLaCarte_revoke",
			ARTICLE:              "article",
			ARTICLE_REVOKE:       "article_revoke",
		},
		regFlow_status: {
			ONGOING:          "ongoing",
			TIMEOUTING:       "timeouting",
			CLOSED_COMPLETED: "closedCompleted",
			CLOSED_USER:      "closedUser",
			CLOSED_TIMEOUT:   "closedTimeout",
		},
		regFlow_step: {
			NEW_REASON_FRANCHISEE_SCHEDULE: "newReasonFranchiseeSchedule",
			CHECKOUT:                       "checkout",
			COMPLETED:                      "completed",
		},
		clientToken_type: {
			MAKEUP_ADD:   "makeUp_add",
			MAKEUP_USAGE: "makeUp_usage",
			TRIAL_ADD:    "trial_add",
			TRIAL_USAGE:  "trial_usage",
		},
		clientSessionContract_status: {
			DRAFT:                       "draft",
			ACTIVE:                      "active",
			COMPLETED:                   "completed",
			TERMINATED_FOR_UP_DOWNGRADE: "terminatedForUpDowngrade",
			TERMINATED_FOR_FULL_UNREG:   "terminatedForFullUnreg",
		},
		clientPayment_type: {
			CLIENT_SESSION_CONTRACT_INITIAL_INSTALLMENT:    "clientSessionContractInitialInstallment",
			CLIENT_SESSION_CONTRACT_PP_AINSTALLMENT:        "clientSessionContractPPAInstallment",
			CLIENT_SESSION_CONTRACT_TERMINATION_SETTLEMENT: "clientSessionContractTerminationSettlement",
			OTHER:                                          "other",
		},
		clientPayment_status: {
			UPCOMING:  "upcoming",
			OVERDUE:   "overdue",
			PROCESSED: "processed",
			VOID:      "void",
		},
		logRouteParser_method: {
			GET:    "GET",
			POST:   "POST",
			PUT:    "PUT",
			PATCH:  "PATCH",
			DELETE: "DELETE",
		},
		clientSessionContract_type: {
			NORMAL_PRESENTIAL: "normal_presential",
			NORMAL_VIRTUAL:    "normal_virtual",
			NORMAL_COMBO:      "normal_combo",
		},
		clientTokenSummary: {
			TRIALS_ANY_FRANCHISEE: "*",
		},
		session_season: {
			WINTER: "winter",
			SPRING: "spring",
			SUMMER: "summer",
			AUTUMN: "autumn",
		},
		promoCode_usageType: {
			SINGLE_NEW_CLIENTS:   "single_newClients",
			SINGLE_ALL:           "single_all",
			MULTIPLE_NEW_CLIENTS: "multiple_newClients",
			MULTIPLE_ALL:         "multiple_all",
		},
		/* 🚀↑app>App.js>MyApp>consts↑🚀 */
	};
	
	_currentSessionInfo        = null;  //Instance of Model_CurrentSessionInfo, loaded in _abstract_boot_await(), via server's RouteParser_CPA_base::_abstract_coreCalls_boot_customData()
	_currentSessionInfo_dt_now = null;  //Ptr on Model_CurrentSessionInfo::dt_now
	//Helpers
	_currentSessionInfo_dt_now_weekday               = null;
	_currentSessionInfo_dt_now_YmdHis                = null;
	_currentSessionInfo_dt_now_Ymd                   = null;
	_currentSessionInfo_session_name_modelField      = null;  //A B_REST_ModelField_DB instance
	_currentSessionInfo_next_session_name_modelField = null;  //A B_REST_ModelField_DB instance
	
	
	
	constructor()
	{
		super({
			flags: {
				heartbeat_freq_secs:     false, //NOTE: Could maybe read from server on boot (like server version), so even if apps are cached, server could force, but why would freq evolve ?
				heartbeat_authOnly:      false,
				defaultPagingSize:       15,
				debug_locPaths:          false,
				debug_fieldNamePaths:    false,
				debug_responses:         false,
				debug_beforeReload:      false,
				debug_ignorePerms:       false,
				debug_authReloadErrs:    false,
				onErr_breakpoint:        false,
				autoVerifyRecoveryEmail: true,
				boot_cache:              false,
			},
			appLangs: {
				fr: locMsgs_custom_fr,
				en: locMsgs_custom_en,
			},
			appComponent: App,
			globalCSSVars: {
				"--bREST-BrFieldDb_isDirty_color": "#00A0D2", // use accent color
			},
			brFieldDbAttrs: {outlined:true, rounded:true, dense:true, labelAbove:true, uppercased:false},
			brFieldFileAttrs: {},
			vuetifyThemeOptions: {
				dark: true,
				themes: {
					dark: {
						primary:   "#000000",
						secondary: "#484848",
						tertiary:  "#FFFFFF",
						accent:    "#00A0D2",
						success:   "#00A651",
						warning:   "#FEB200",
						error:     "#DC1E1E",
						blue:      "#00A0D2",
					},
					light: {},
				},
				options: {
					customProperties: true,
				},
			},
			pickerDefs: {
				staffList: {
					component_ifGenericList_moduleName: "staff",
					reuseMode:                          B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
				franchiseeParkList: {
					component_ifGenericList_moduleName: "franchiseePark",
					reuseMode:                          B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
				clientList: {
					component_ifGenericList_moduleName: "client",
					reuseMode:                          B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
				franchiseeList: {
					component_ifGenericList_moduleName: "franchisee",
					reuseMode:                          B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
				/* 🚀↑app>App.js>MyApp>constructor>pickerDefs↑🚀 */
			},
		});
		
		this._constructor_mountVue_install_$bREST(); //Check method docs for what that does. Don't rem or place before super()
	}
	
	static get consts() { return  this._consts; }
	       get consts() { return MyApp._consts; }
	
	get businessConfig_hasVirtual()                { return this._businessConfig.custom.wp_virtual_urlBase!==null; }
	get businessConfig_eventTitle_appendDuration() { return this._businessConfig.custom.eventTitle_appendDuration; }
	
	/*
	WARNING:
		From server's Model_CurrentSessionInfo::dt_now, so if not updated frequently / if we don't reload page often, will be wrong
		However, in frontend we have a setInterval() to increase time automatically wo needing API calls
		Has more fields that this; check its docs for all we can get
	*/
	get currentSessionInfo()                                 { return this._currentSessionInfo;                                               }
	get currentSessionInfo_dt_now()                          { return this._currentSessionInfo_dt_now;                                        }
	get currentSessionInfo_dt_now_weekday()                  { return this._currentSessionInfo_dt_now_weekday;                                }
	get currentSessionInfo_dt_now_YmdHis()                   { return this._currentSessionInfo_dt_now_YmdHis;                                 }
	get currentSessionInfo_dt_now_Ymd()                      { return this._currentSessionInfo_dt_now_Ymd;                                    }
	get currentSessionInfo_d_today()                         { return this._currentSessionInfo.select("d_today").val;                         } //NOTE: Auto updated like currentSessionInfo_dt_now, but as Ymd
	get currentSessionInfo_session_fk()                      { return this._currentSessionInfo.select("session_fk").val;                      }
	get currentSessionInfo_session_name()                    { return this._currentSessionInfo_session_name_modelField.val_currentLang;       }
	get currentSessionInfo_d_priorityReg_from()              { return this._currentSessionInfo.select("d_priorityReg_from").val;              } //As Ymd, not Date
	get currentSessionInfo_d_genReg_from()                   { return this._currentSessionInfo.select("d_genReg_from").val;                   } //As Ymd, not Date
	get currentSessionInfo_d_genReg_to()                     { return this._currentSessionInfo.select("d_genReg_to").val;                     } //As Ymd, not Date
	get currentSessionInfo_d_events_from()                   { return this._currentSessionInfo.select("d_events_from").val;                   } //As Ymd, not Date
	get currentSessionInfo_d_events_to()                     { return this._currentSessionInfo.select("d_events_to").val;                     } //As Ymd, not Date
	get currentSessionInfo_calc_dayCount()                   { return this._currentSessionInfo.select("calc_dayCount").val;                   }
	get currentSessionInfo_calc_weekCount()                  { return this._currentSessionInfo.select("calc_weekCount").val;                  }
	get currentSessionInfo_calc_weekDates()                  { return this._currentSessionInfo.select("calc_weekDates").val;                  }
	get currentSessionInfo_calc_live_weekIdx()               { return this._currentSessionInfo.select("calc_live_weekIdx").val;               }
	get currentSessionInfo_calc_live_dayOffset()             { return this._currentSessionInfo.select("calc_live_dayOffset").val;             }
	get currentSessionInfo_next_session_fk()                 { return this._currentSessionInfo.select("next_session_fk").val;                 }
	get currentSessionInfo_next_session_name()               { return this._currentSessionInfo_next_session_name_modelField.val_currentLang;  }
	get currentSessionInfo_next_d_priorityReg_from()         { return this._currentSessionInfo.select("next_d_priorityReg_from").val;         } //As Ymd, not Date
	get currentSessionInfo_next_d_genReg_from()              { return this._currentSessionInfo.select("next_d_genReg_from").val;              } //As Ymd, not Date
	get currentSessionInfo_next_d_genReg_to()                { return this._currentSessionInfo.select("next_d_genReg_to").val;                } //As Ymd, not Date
	get currentSessionInfo_next_d_events_from()              { return this._currentSessionInfo.select("next_d_events_from").val;              } //As Ymd, not Date
	get currentSessionInfo_next_d_events_to()                { return this._currentSessionInfo.select("next_d_events_to").val;                } //As Ymd, not Date
	get currentSessionInfo_next_calc_dayCount()              { return this._currentSessionInfo.select("next_calc_dayCount").val;              }
	get currentSessionInfo_next_calc_weekCount()             { return this._currentSessionInfo.select("next_calc_weekCount").val;             }
	get currentSessionInfo_next_calc_weekDates()             { return this._currentSessionInfo.select("next_calc_weekDates").val;             }
	get currentSessionInfo_next_calc_live_weekIdx()          { return this._currentSessionInfo.select("next_calc_live_weekIdx").val;          }
	get currentSessionInfo_next_calc_live_dayOffset()        { return this._currentSessionInfo.select("next_calc_live_dayOffset").val;        }
	
	get currentSessionInfo_isWithinPeriod_reg()    { return this.currentSessionInfo_d_priorityReg_from<=this._currentSessionInfo_dt_now_Ymd&&this._currentSessionInfo_dt_now_Ymd<=this.currentSessionInfo_d_events_to; } 
	get currentSessionInfo_isWithinPeriod_events() { return this.currentSessionInfo_d_events_from     <=this._currentSessionInfo_dt_now_Ymd&&this._currentSessionInfo_dt_now_Ymd<=this.currentSessionInfo_d_events_to; } 
	
	//For MainLayout.vue & PublicLayout.vue
	get currentSessionInfo_debug_show() { return this._businessConfig.custom.debug_currentSessionInfo; }
	get currentSessionInfo_debug_html()
	{
		if (!this.currentSessionInfo_session_fk) { return `${this.currentSessionInfo_dt_now_YmdHis} -> no session`; }
		return `${this.currentSessionInfo_dt_now_YmdHis} -> session #${this.currentSessionInfo_session_fk} ${this.currentSessionInfo_session_name}<br />(${this.currentSessionInfo_d_priorityReg_from} to ${this.currentSessionInfo_d_events_from} to ${this.currentSessionInfo_d_events_to})`;
	}
	
	//To show events PK + occurrenceIdx
	get events_debug_show() { return this._businessConfig.custom.debug_events_show; }
	
	get userType_isClient() { return this.user_type_is(MyApp._consts.user_types.CLIENT); }
	get userType_isStaff()  { return this.user_type_is(MyApp._consts.user_types.STAFF);  }
	
	get roles_staff_visibleFranchisees()     { return this.userType_isStaff ?  this.perms_extraData_get("staff_visibleFranchisees") : null;                               }
	get roles_staff_visibleFranchisees_all() { return this.userType_isStaff && this.perms_extraData_get("staff_visibleFranchisees")===MyApp.staff_calc_roles_summary_all; }
	
	get roles_isAnyRoleGod()                 { return this.perms_can("roles_staff_god");                                                                                  }
	get roles_isAnyRoleAdmin()               { return this.perms_can("roles_staff_admin");                                                                                } //NOTE: For here and all below, don't add a OR on x_isAnyRoleGod; should just do that user has both perm tags, so we have almost no refs to god in code
	get roles_isAnyRoleFranchiseeOwner()     { return this.perms_can("roles_staff_franchiseeOwner");                                                                      }
	get roles_isAnyRoleFranchiseeManager()   { return this.perms_can("roles_staff_franchiseeManager");                                                                    }
	get roles_isAnyRoleCoach()               { return this.perms_can("roles_staff_coach");                                                                                }
	get roles_isAnyRoleCoachSupervisor()     { return this.perms_can("roles_staff_coachSupervisor");                                                                      }
	get roles_isAnyRoleTrainee()             { return this.perms_can("roles_staff_trainee");                                                                              }
	get roles_isAnyRoleManagerAndUp()        { return this.roles_isAnyRoleAdmin||this.roles_isAnyRoleFranchiseeOwner||this.roles_isAnyRoleFranchiseeManager;              }
	get perms_mySchedule_canView()
	{
		if (this.userType_isClient) { return true;  } //Means logged
		if (!this.userType_isStaff) { return false; }
		
		//NOTE: Don't show nothing if just a StaffRole::TYPE_TRAINEE
		const STAFF_SHOW_MY_SCHEDULE_TO_MANAGER_ETC_ANYWAYS = true; //Because otherwise admins and other wanting to show a demo freak out
		return this.roles_isAnyRoleCoach || (STAFF_SHOW_MY_SCHEDULE_TO_MANAGER_ETC_ANYWAYS&&this.roles_isAnyRoleManagerAndUp);
	}
	
	//One of consts::wpVirtualViewPerms.X
	get perms_both_wp_virtual_viewPerms()
	{
		return this.perms_extraData_get("both_wp_virtual_viewPerms") ?? MyApp._consts.wpVirtualViewPerms.NONE; //JIC. Note that it should already be handled correctly for public user in server's RouteParser_CPA_base::perms_reEval_all()
	}
		get perms_both_wp_virtual_viewPerms_all()      { return this.perms_both_wp_virtual_viewPerms===MyApp._consts.wpVirtualViewPerms.ALL;       }
		get perms_both_wp_virtual_viewPerms_one()      { return this.perms_both_wp_virtual_viewPerms===MyApp._consts.wpVirtualViewPerms.ONE;       }
		get perms_both_wp_virtual_viewPerms_freeOnly() { return this.perms_both_wp_virtual_viewPerms===MyApp._consts.wpVirtualViewPerms.FREE_ONLY; }
		get perms_both_wp_virtual_viewPerms_none()     { return this.perms_both_wp_virtual_viewPerms===MyApp._consts.wpVirtualViewPerms.NONE;      }
	
		_abstract_perms_evalComplexPerm(complexPermName, details=null)
		{
			/*
				🚀❓
					Check docs in frontend's B_REST_App_base:
						perms_x()
						debug_ignorePerms
						perms_can()
						perms_change()
						user_createFromObj()
						appData_clear()
						_calls_interceptCoreProps()
						routes_hasPerms()
					Check refs to output_json_injectCore_perms() in server's RouteParser_base.php
				🚀❓
			*/
			
			switch (complexPermName)
			{
				case "accessFranchisee":
					if (!details) { this.throwEx(`Expected franchisee PK`); }
					if (this.roles_staff_visibleFranchisees_all) { return true; }
					return this.roles_staff_visibleFranchisees.includes(details);
				default: this.throwEx(`Unknown complexPermName "${complexPermName}"`);
			}
		}
	
	get wpUrl_faq()             { return this._wpUrl_x({fr:"faq",                    en:"en/faq"});                }
	get wpUrl_programs()        { return this._wpUrl_x({fr:"programmes",             en:"en/programs"});           }
	get wpUrl_memberships()     { return this._wpUrl_x({fr:"abonnements",            en:"en/memberships"});        }
	get wpUrl_contactUs()       { return this._wpUrl_x({fr:"nous-joindre",           en:"en/contact-us"});         }
	get wpUrl_giveOpinion()     { return this._wpUrl_x({fr:"nous-joindre",           en:"en/contact-us"});         } //Don't really know... Some say it should be a public google review page per franchisee, but we don't know for which franchisee so...
	get wpUrl_virtualOffer()    { return this._wpUrl_x({fr:"entrainements-virtuels", en:"en/virtual-membership"}); }
	get wpUrl_virtualPlatform() { return `${this._businessConfig.custom.wp_virtual_urlBase}/`;          }
		_wpUrl_x(urls) { return `${this._businessConfig.custom.wp_urlBase}/${urls[this.locale_lang]}/`; }
	
	//One of privacy|termsAndConditions|spap. Yields abs URL
	policyLink(which) { return `/_businessFiles/policies/${MyApp._consts.policiesLinks[which][this.locale_lang]}`; }
	
	//Server's bREST_Custom::money_x() should match w frontend's MyApp::money_x()
	money_format(val)
	{
		this.utils.number_assert(val);
		const formatted = val>=0 ? this.utils.number_format(val,2,"."," ") : `(${this.utils.number_format(-val,2,"."," ")})`;
		return this._businessConfig.custom.moneyFormat_dollarRight ? `${formatted} $` : `$ ${formatted}`;
	}
	//Server's bREST_Custom::money_x() should match w frontend's MyApp::money_x()
	money_unFormat(val)
	{
		this.utils.string_assert(val);
		const match = val.match(/^(\$ )?(\()?(\d+\.\d+)(\))?( \$)?$/);
		if (!match) { this.throwEx(`Received amount not well formatted: ${val}`); }
		const isNeg     = match[2]==="(";
		const absAmount = parseFloat(match[3]);
		return isNeg ? -absAmount : absAmount;
	}
	
	d_format_long(dt)  { return this._d_format_x(dt,"long");  } //Ex "Mercredi 30 septembre"
	d_format_short(dt) { return this._d_format_x(dt,"short"); } //Ex "Merc 30 sept"
		_d_format_x(dt, propName) //short|long
		{
			this.utils.dt_assert_isValid(dt);
			
			this.utils.console_todo([`Not respecting bilingual order & struct`]);
			
			const weekday    = this.t_custom(`app.consts.calendar.weekdays.${dt.getDay()}.${propName}`);
			const monthDay   = dt.getDate();
			const monthLabel = this.t_custom(`app.consts.calendar.months.${dt.getMonth()}.${propName}`);
			return `${weekday} ${monthDay} ${monthLabel}`;
		}
	
	get consts_durations_asTranslatedItems() { return this._consts_x_asTranslatedItems("durations");                      }
	get consts_weekdays_asTranslatedItems()  { return this._consts_x_asTranslatedItems("weekdays", "calendar.", ".long"); } //WARNING: Path struct also used in Calendar.js
	get consts_seasons_asTranslatedItems()   { return this._consts_x_asTranslatedItems("seasons",  "calendar.", ".long"); } //WARNING: Path struct also used in Calendar.js
		/*
		Converts a struct like {SUNDAY:"sunday", MONDAY:"monday", TUESDAY:"tuesday", ...} into:
			[
				{value:"sunday",key:"sunday", text:"Dimanche",label:"Dimanche"},
				...
			]
		So it can work easily with <v-select> and <br-field-db :items>
		*/
		_consts_x_asTranslatedItems(which, locIntermediatePath="", locSuffix="")
		{
			const locBasePath = `app.consts.${locIntermediatePath}${which}`;
			const items       = [];
			
			for (const loop_tag of Object.values(MyApp._consts[which]))
			{ 
				const loop_label = this.t_custom(`${locBasePath}.${loop_tag}${locSuffix}`);
				
				items.push({value:loop_tag,key:loop_tag, text:loop_label,label:loop_label});
			}
			
			return items;
		}
	
	
	get shouldShowBackNavBtn() { return this._shouldShowBackNavBtn; }
	
	/* 🚀↑app>App.js>MyApp>generalMethods↑🚀 */

	_abstract_routes_defineRoutes()
	{
		const RegFlowComponent = ()=>import("./routerViews/regFlow/Index.vue");
		
		this._routes_define_x_setCurrentLayoutComponent(PublicLayoutComponent);
			/*
				🚀❓
					Also have:
						this._routes_define_landpage(🚀{app>routePathLangMapOrString-landpage}🚀, ()=>import("./routerViews/???/Index.vue"));
					WARNING:
						Defining or not _routes_define_landpage(), _routes_define_login() & _routes_define_profile() must be consistent w server's bREST_Custom::_abstract_uiRoutes_has_x()
				🚀❓
			*/
			this._routes_define_404(     {fr:"/oups/",             en:"/woops/"},       ()=>import("./routerViews/404/Index.vue"));
			this._routes_define_403(     {fr:"/permissions/",      en:"/permissions/"}, ()=>import("./routerViews/403/Index.vue"));
			this._routes_define_landpage({fr:"/",                  en:"/"},             ()=>import("./routerViews/landpage/Index.vue")); //WARNING: Read generator warning above
			this._routes_define_resetPwd({fr:"/reinitialisation/", en:"/reset/"},       ()=>import("./routerViews/resetPwd/Index.vue")); //WARNING: URL must match server's bREST_Custom::_abstract_uiRoutes_resetPwd_paths() URLs
			
			this._routes_define_public("sandbox",              "/sandbox/",              ()=>import("./routerViews/sandbox/Index.vue"));
			this._routes_define_public("sandbox_gmap",         "/sandbox/gmap/",         ()=>import("./routerViews/sandbox/GMap.vue"));
			this._routes_define_public("sandbox_unitTests_dt", "/sandbox/unitTests/dt/", ()=>import("./routerViews/sandbox/UnitTests-dt.vue"));
			this._routes_define_auth(  "sandbox_cc",           "/sandbox/cc/",           ()=>import("./routerViews/sandbox/CC.vue"));
			
		
		this._routes_define_x_setCurrentLayoutComponent(MainLayoutComponent);
			
				/*
				WARNING:
					If we change URLs here, has impacts in server's:
						RouteParser_RegFlow::$_FILTERS_REASONS
						RouteParser_RegFlow::$_REASONS_UI_ROUTES
						RouteParser_RegFlow::_check_load_reCreate_cycleTimeout_kickOut()
				IMPORTANT: If we add routes here, add them to MainLayout.vue::DONT_RESTRAIN_WIDTH_ROUTE_NAMES
				*/
					this._routes_define_public("regFlow-normal",  {fr:"/inscriptions/",          en:"/registrations/"},  RegFlowComponent);
					this._routes_define_public("regFlow-makeUp",  {fr:"/reserver-reprise/",      en:"/makeup-booking/"}, RegFlowComponent);
					this._routes_define_public("regFlow-trial",   {fr:"/reserver-essai-gratuit/",en:"/trial-booking/"},  ()=>import("./routerViews/regFlow/trial_newUX/Index.vue"));
					this._routes_define_auth("regFlow-urlGenerator", {fr:"/liens-marketing/", en:"/marketing-links/"}, ()=>import("./routerViews/regFlow/urlGenerator/Index.vue"));
					
			
			this._routes_define_auth("home", {fr:"/accueil/",en:"/home/"}, ()=>import("./routerViews/home/Index.vue")); //WARNING: If we change URLs here, has impacts in server's Model_Email::_abstract_email_links_uiRoutes()
			
			this._routes_define_auth("configs", {fr:"/configs/",en:"/configs/"}, ()=>import("./routerViews/menusWTiles/Configurations.vue"));
				this._routes_define_genericListFormModule("configCity", {fr:"/configs/villes/", en:"/configs/cities/"});
				this._routes_define_genericListFormModule("configAdministrativeRegion", {fr:"/configs/regions/", en:"/configs/regions/"});
				this._routes_define_genericListFormModule("configSourceMarketing", {fr:"/configs/sourcesMarketing/", en:"/configs/marketingSources/"});
				this._routes_define_genericListFormModule("configCorpoRefusalReason", {fr:"/configs/raisonsRefusCorpo/", en:"/configs/corpoRefusalReasons/"});
				this._routes_define_genericListFormModule("configCorpoService", {fr:"/configs/servicesCorpo/", en:"/configs/corpoServices/"});
				this._routes_define_genericListFormModule("configClientNote", {fr:"/configs/notesClients/", en:"/configs/clientNotes/"});
				this._routes_define_genericListFormModule("configProgram", {fr:"/configs/programmes/", en:"/configs/programs/"});
				this._routes_define_genericListFormModule("configSessionMembership", {fr:"/configs/abonnements/", en:"/configs/sessionMemberships/"});
				this._routes_define_genericListFormModule("session", {fr:"/configs/sessions/", en:"/configs/sessions/"});
			
			this._routes_define_auth("franchiseeSessionInfo", {fr:"/grilleDePrix/", en:"/priceGrid/"}, ()=>import("./routerViews/modules/franchiseeSessionInfo/Management.vue"));
			
			this._routes_define_auth("staffRelated", {fr:"/personnelCPA/",en:"/staffRelated/"}, ()=>import("./routerViews/menusWTiles/StaffRelated.vue"));
				this._routes_define_genericListFormModule("staff", {fr:"/personnelCPA/personnelCpa/", en:"/staffRelated/cpaStaff/"});
				this._routes_define_genericListFormModule("configCoachCertification", {fr:"/personnelCPA/certificationsEntraineurs/", en:"/staffRelated/coachCertifications/"});
			
			this._routes_define_auth("calendar_presentialManagement", {fr:"/horaire/gestion/", en:"/schedule/management/"}, ()=>import("./routerViews/modules/event/PresentialManagementCalendar.vue"));
			this._routes_define_auth("calendar_mySchedule",           {fr:"/horaire/mon-calendrier/", en:"/schedule/my-calendar/"}, ()=>import("./routerViews/modules/event/MySchedule.vue"));
			
			this._routes_define_profile({fr:"/mon-profil/", en:"/my-profile/"}, ()=>import("./routerViews/myProfile/Index.vue"));
			
			this._routes_define_genericListFormModule("franchisee", {fr:"/franchises/", en:"/franchisees/"});
			this._routes_define_genericListFormModule("franchiseePark", {fr:"/parcs/", en:"/parks/"});
				this._routes_define_genericListFormSubModule("franchisee>franchiseePark");
			
			this._routes_define_genericListFormModule("client", {fr:"/clients/", en:"/members/"});
			this._routes_define_genericListFormModule_listOnly("clientSessionContract", {fr:"/contrats/", en:"/contracts/"});
			this._routes_define_genericListFormModule_listOnly("clientPayment", {fr:"/paiements/", en:"/payments/"});
				this._routes_define_genericListFormSubModule("client>clientSessionContract");
				this._routes_define_genericListFormSubModule("client>clientPayment");
			
			this._routes_define_genericListFormModule("promoCode", {fr:"/codes-promo/", en:"/promo-codes/"});
			/* 🚀↑app>App.js>MyApp>_abstract_routes_defineRoutes>mainLayoutDefines↑🚀 */
			/*
                🚀❓
                    To define sub model lists within a module (ex citizen having animals, invoices & payments), do like:
                        this._routes_define_genericListFormModule("citizen", "/citizens/");
                        this._routes_define_genericListFormModule("animal",  "/animals/");
                        this._routes_define_genericListFormModule("invoice", "/invoices/");
                        this._routes_define_genericListFormModule("payment", "/payments/");
                        this._routes_define_genericListFormSubModule("citizen>animal");
                        this._routes_define_genericListFormSubModule("citizen>invoice");
                        this._routes_define_genericListFormSubModule("citizen>payment");
                🚀❓
            */
	}
	
	async _abstract_boot_await(response, corePropsThenDirectives)
	{
		// 🚀❓ Check docs in B_REST_App_base::boot_await() ❓🚀
		
		/*
		This is set via server's RouteParser_CPA_base::_abstract_coreCalls_boot_customData()
		WARNING:
			We use this for its dt_now too, so if server doesn't update it frequently, it'll be wrong
			To keep ourself in sync wo needing extra API calls, we have a setInterval doing ++ on time, but it's not accurate if we flip over a day,
				because other fields like calc_current_weekIdx, calc_current_dayOffset won't be updated
		*/
		{
			this._currentSessionInfo = this.models_make("CurrentSessionInfo", response.data.custom.currentSessionInfo);
			
			const sessionList = this.sharedLists_getSrc("sessionList");
			this._currentSessionInfo_session_name_modelField      = sessionList.get_byPK(this.currentSessionInfo_session_fk).select("name");
			this._currentSessionInfo_next_session_name_modelField = this.currentSessionInfo_next_session_fk ? sessionList.get_byPK(this.currentSessionInfo_next_session_fk).select("name") : null;
			
			const dt_now_YmdHis = this._currentSessionInfo.select("dt_now").val; //Shortcut
			if (!dt_now_YmdHis) { this.throwEx(`Got no current session dt_now, so calendar and such won't work`); }
			
			const dt_now = B_REST_Utils.dt_fromYmdHis(dt_now_YmdHis);
			this._abstract_boot_await_updateCurrentSessionInfo_dt_now(dt_now);
			
			//Make sure interval fits w real time's changing of minutes, otherwise will unleash hell. Don't use await sleep, or boot could take 59s
			const secsUntilNextMinuteStart = 60-new Date().getSeconds();
			setTimeout(() =>
			{
				B_REST_Utils.console_warn(`Updating CurrentSessionInfo; can cause calendar to refresh`);
				const dt_now = B_REST_Utils.dt_deltaSeconds(this._currentSessionInfo_dt_now,MyApp.CURRENT_SESSION_INFO_DT_NOW_UPDATE_INTERVAL_SECS);
				this._abstract_boot_await_updateCurrentSessionInfo_dt_now(dt_now);
				
				setInterval(() => 
				{
					B_REST_Utils.console_warn(`Updating CurrentSessionInfo; can cause calendar to refresh`);
					const dt_now = B_REST_Utils.dt_deltaSeconds(this._currentSessionInfo_dt_now,MyApp.CURRENT_SESSION_INFO_DT_NOW_UPDATE_INTERVAL_SECS);
					this._abstract_boot_await_updateCurrentSessionInfo_dt_now(dt_now);
				}, MyApp.CURRENT_SESSION_INFO_DT_NOW_UPDATE_INTERVAL_SECS*1000);
			}, secsUntilNextMinuteStart*1000);
		}
	}
		_abstract_boot_await_updateCurrentSessionInfo_dt_now(dt_now)
		{
			this._currentSessionInfo_dt_now = dt_now;
			
			//Make sure original props always match, JIC someone uses these instead
			this._currentSessionInfo.select("dt_now" ).val = this._currentSessionInfo_dt_now;
			this._currentSessionInfo.select("d_today").val = B_REST_Utils.dt_toYmd(this._currentSessionInfo_dt_now);
			
			this._currentSessionInfo_dt_now_weekday = B_REST_Utils.dt_toWeekday(this._currentSessionInfo_dt_now);
			this._currentSessionInfo_dt_now_YmdHis  = B_REST_Utils.dt_toYmdHis( this._currentSessionInfo_dt_now);
			this._currentSessionInfo_dt_now_Ymd     = B_REST_Utils.dt_toYmd(    this._currentSessionInfo_dt_now);
		}
	_abstract_commonDefs_setupDescriptorHooks()
	{
		// 🚀❓ Check docs in B_REST_App_base::_commonDefs_setupDescriptorHooks() & B_REST_App_base::_calls_interceptCoreProps() ❓🚀
	}
	_abstract_beforeUnload_generalHook()
	{
		/*
			🚀❓
				Check docs in B_REST_App_base's
					_boot_setUnbooting()
					boot_await()
					reboot()
					routes_reload()
					routes_go_back()
					routes_go_routeInfo()
					routes_go_path()
					routes_go_name()
					routes_go_external()
			🚀❓
		*/
		
		this._onBeforeUnload_regFlow_checkNotifyAbandoningCart(); //NOTE: Async, but we can't wait for it in a before unload event
		
		return true;
	}
		async _onBeforeUnload_regFlow_checkNotifyAbandoningCart()
		{
			const RegFlow = (await import("@/custom/components/regFlow/RegFlow.js")).default; //NOTE: We can't put that import anywhere in this .js, or everything breaks
			
			if (RegFlow.instance) { RegFlow.instance.beaconNotifyAbandoningCart(); } //Check server's Model_RegFlow::isMaybeAbandoningCart_x() docs for how to make this work
		}
	_abstract_user_createFromObj(userModel, userObj)
	{
		// 🚀❓ When an API call / local storage rets an {} of a user ❓🚀
		
		if (!userModel.select("type").val)
		{
			userModel.select("type").val = null;
		}
	}
	get _abstract_user_displayName()
	{
		// 🚀❓ For B_REST_App_base::user_displayName() ❓🚀
		
		return this._user?.select_firstNonEmptyVal("firstName+lastName|firstName|lastName|userName|recoveryEmail") || "Anonyme";
	}
	/*
	Check if we have a RegFlow instance to pass to the call, to later bind the client to the flow
	Check server's RouteParser_CPA_base::_setSpecs_fromRequest_checkHasRegFlowHash_setClient_injectForceReload() docs for flow of info between frontend & server for that
	*/
	async _abstract_login_sudoX_beforeCall(request,extraData=null)
	{
		const regFlow = extraData?.regFlow ?? null; //Instance of RegFlow
		
		if (regFlow)
		{
			const RegFlow = (await import("@/custom/components/regFlow/RegFlow.js")).default; //NOTE: We can't put that import anywhere in this .js, or everything breaks
			
			//Prevent request from trying to JSON.parse() the instance, and add it in the request's header instead
			B_REST_Utils.object_removeProp(request.data.extraData, "regFlow");
			RegFlow.apiCalls_injectRegFlowHash(request, regFlow);
		}
	}
	async _abstract_calls_interceptCoreProps_customDataNode(customProps,thenDirectives)
	{
		// 🚀❓ When an API call rets a custom node of data from server's RouteParser_base::output_json_injectCore_customData() ❓🚀
	}
	async _abstract_calls_tweakResponse_hook(response, corePropsThenDirectives)
	{
		// 🚀❓ During an API call, allows to mess w a API B_REST_Response's data ❓🚀
	}
	_abstract_calls_afterCall_general_handler(response)
	{
		// 🚀❓ Called after any API call, no matter if it succeeded or not ❓🚀
	}
	async _abstract_routes_hasPerms(routeInfo)
	{
		// 🚀❓ Used in B_REST_App_base::routes_hasPerms() ❓🚀
		
		return true;
	}
	_abstract_routes_authDefaultRouteName()
	{
		/*
			🚀❓
				Where we must land after login, unless specified by server
				NOTE: Should match w backend's redirect logic in:
						RouteParser_base::_overridable_setUser_redirectableActions_injectRedirectionDirective()
						bREST_Custom::_abstract_uiRoutes_defaultRouteName_auth()
				Ex:
					B_REST_VueApp_RouteDef.NAME_PROFILE
					B_REST_VueApp_RouteDef.NAME_LANDPAGE
					"user-list"
					"someOtherModule-list"
			🚀❓
		*/
		
		return "home";
	}
	async _abstract_routes_beforeNavigationChange(routeInfo_to_proposedAction, routeInfo_to_intended, routeInfo_from=null)
	{
		/*
			🚀❓
				Check B_REST_App_base::_abstract_routes_beforeNavigationChange() docs; ret bool or another B_REST_VueApp_RouteInfo instance
				Also use this to await loading extra data etc
			🚀❓
		*/
		
		if (routeInfo_to_intended.routeDef?.type_isPublicLandpage && this.user_isAuth) { return this.routeDefs_profile.toRouteInfo(); }
		
		return true;
	}
	_abstract_routes_evalTravelDirection(travelDir,routeInfo_to,routeInfo_from=null)
	{
		this._shouldShowBackNavBtn = travelDir===MyApp.ROUTES_TRAVEL_DIR_TO_CHILD; //WARNING: For now, not accurate, because if we start in a sub page, we have no nav triggering calculating this, or maybe we should just always be able to go back
		
		return travelDir;
	}
	_abstract_routes_afterNavigationChange(routeInfo_to, routeInfo_from=null)
	{
		//🚀❓ Called after a navigation change. Check B_REST_App_base::_routes_beforeNavigationChange() ❓🚀
	}
};


MyApp.instance_init();
